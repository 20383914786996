import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import exportingInit from 'highcharts/modules/exporting';

exportingInit(Highcharts);

const CalculatorChart = ({ orbixY, legacysystemY }) => {
  useEffect(() => {
    // Highcharts theme
    Highcharts.theme = {
      colors: ['#816bff', 'rgba(255, 255, 255, 0.55)', '#f4bd0e', '#09c2de', '#e85347', '#816bff'],
      chart: {
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'var(--bs-body-font-family)',
        },
        plotBorderColor: '#606063',
      },
      title: {
        style: {
          color: 'rgba(255, 255, 255, 0.55)',
          fontWeight: 'bold',
          fontSize: '22px',
        },
      },
      subtitle: {
        style: {
          color: '#E0E0E3',
        },
      },
      xAxis: {
        gridLineColor: 'rgba(255, 255, 255, 0.12)',
        labels: {
          style: {
            color: '#FFF',
            fontSize: '12px',
          },
        },
        lineColor: '#0000',
        title: {
          style: {
            color: '#A0A0A3',
          },
        },
      },
      yAxis: {
        gridLineColor: 'rgba(255, 255, 255, 0.12)',
        labels: {
          style: {
            color: '#FFF',
            fontSize: '12px',
          },
        },
        lineColor: 'rgba(255, 255, 255, 0.12)',
        minorGridLineColor: '#505053',
        tickColor: 'rgba(255, 255, 255, 0.12)',
        tickWidth: 1,
        title: {
          style: {
            color: '#A0A0A3',
          },
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        style: {
          color: '#F0F0F0',
        },
      },
      plotOptions: {
        series: {
          opacity: '0.8',
          dataLabels: {
            color: '#F0F0F3',
            style: {
              fontSize: '13px',
            },
          },
          marker: {
            lineColor: '#333',
          },
        },
        boxplot: {
          fillColor: '#505053',
        },
        candlestick: {
          lineColor: 'white',
        },
        errorbar: {
          color: 'white',
        },
      },
      legend: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        itemStyle: {
          color: '#E0E0E3',
        },
        itemHoverStyle: {
          color: '#FFF',
        },
        itemHiddenStyle: {
          color: '#606063',
        },
        title: {
          style: {
            color: '#C0C0C0',
          },
        },
      },
      credits: {
        style: {
          color: '#666',
        },
        enabled: false,
      },
      labels: {
        style: {
          color: '#707073',
        },
      },
      drilldown: {
        activeAxisLabelStyle: {
          color: '#F0F0F3',
        },
        activeDataLabelStyle: {
          color: '#F0F0F3',
        },
      },
      navigation: {
        buttonOptions: {
          symbolStroke: '#DDDDDD',
          theme: {
            fill: '#505053',
          },
        },
      },
      rangeSelector: {
        buttonTheme: {
          fill: '#505053',
          stroke: '#000000',
          style: {
            color: '#CCC',
          },
          states: {
            hover: {
              fill: '#707073',
              stroke: '#000000',
              style: {
                color: 'white',
              },
            },
            select: {
              fill: '#000003',
              stroke: '#000000',
              style: {
                color: 'white',
              },
            },
          },
        },
        inputBoxBorderColor: '#505053',
        inputStyle: {
          backgroundColor: '#333',
          color: 'silver',
        },
        labelStyle: {
          color: 'silver',
        },
      },
      navigator: {
        handles: {
          backgroundColor: '#666',
          borderColor: '#AAA',
        },
        outlineColor: '#CCC',
        maskFill: 'rgba(255,255,255,0.1)',
        series: {
          color: '#7798BF',
          lineColor: '#A6C7ED',
        },
        xAxis: {
          gridLineColor: '#505053',
        },
      },
      scrollbar: {
        barBackgroundColor: '#808083',
        barBorderColor: '#808083',
        buttonArrowColor: '#CCC',
        buttonBackgroundColor: '#606063',
        buttonBorderColor: '#606063',
        rifleColor: '#FFF',
        trackBackgroundColor: '#404043',
        trackBorderColor: '#404043',
      },
      exporting: {
        enabled: false,
      },
    };

    // Apply the theme
    Highcharts.setOptions(Highcharts.theme);

    // Create the chart
    Highcharts.chart('container', {
      chart: {
        type: 'column',
      },
      title: {
        align: 'left',
        text: 'Cost Comparison Chart (USD)',
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '$ {point.y}',
          },
        },
      },
      series: [
        {
          name: 'Cost',
          colorByPoint: true,
          data: [
            {
              name: 'Orbix',
              y: orbixY,
            },
            {
              name: 'Legacy system',
              y: legacysystemY,
            },
          ],
        },
      ],
    });
  }, [orbixY, legacysystemY]);

  return (
    <div>
      <figure className="highcharts-figure">
        <div id="container"></div>
      </figure>
    </div>
  );
};

export default CalculatorChart;
