import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ScrollToTop from './ScrollToTop'; 
import Home from './pages/home/home.component';
import Partners from './pages/partners/partners.component';
import News from './pages/news/news.component';
import NewsInner from './pages/newsinner/newsinner.component';
import ThankYouPage from './pages/thankyou/thankyou';
import TermsOfServices from './pages/termsofservices/termsofservices.component';
import PrivacyPolicy from './pages/privacypolicy/privacypolicy.component';


function App() {
  return (
    <>
      <ScrollToTop /> {/* Include ScrollToTop */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/:postSlug" element={<NewsInner />} />
        <Route path="/thank-you" element={<ThankYouPage />} />
        <Route path="/terms-of-services" element={<TermsOfServices />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </>
  );
}

export default App;
