// src/components/HeroSection.js
import React from 'react';
import BookDemoButton from '../../BookDemoButton'

const HeroSection = () => {
  return (
    <>
      <section className="position-relative overflow-hidden">
        <div className="container pt-lg-3">
          <div className="row flex-lg-nowrap">
            <div className="col-lg-6 col-xl-5 text-center text-lg-start pt-5 mt-xl-4">
              <h1 className="display-5 pt-5 pb-2 pb-lg-3 mt-2 mt-lg-5">The New Standard of Nondestructive Evaluation</h1>
              <p className="fs-lg mb-3 mb-lg-3">Digitize your operations, protect your assets, and dramatically improve the quality and efficiency of your nondestructive evaluations.</p>
              <BookDemoButton/>
              <div className="pt-5 mt-xl-5"></div>
            </div>

            {/* <!-- Layer parallax --> */}
            <div className="parallax parallax-react mt-4 ms-4 me-lg-0 ms-lg-n5 ms-xl-n3 mt-lg-n4" style={{ transform: 'translate3d()' }}>
              <div className="parallax-layer parallax-layer-react" data-depth="0.1">
                <img src="assets/img/landing/saas-1/hero/xpose_manage_companies.png" width="1416" alt="Layer" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSection;
