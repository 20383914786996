import React, { useState, useEffect, Fragment } from 'react';
import { Link } from "react-router-dom"; 
import Modal from '../../Modal'

const FooterSection = ()=>{

  //Modal
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 15000); // Show modal after 15 seconds

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };
    return(
        <>
         <footer className="footer bg-dark dark-mode   pb-4 pb-lg-5">
        <div className="container text-center pt-lg-3">
          <div className="navbar-brand justify-content-center text-dark mb-2 mb-lg-4">
            <Link to="/" className="navbar-brand" style={{ "paddingLeft": "3rem"}}>
              <img src={`${process.env.PUBLIC_URL}/assets/img/logo_large 1.png`} width="150" alt="orbix" />
            </Link>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-2 col-sm-2"></div>
            <div className="d-flex col-lg-6 col-md-8 col-sm-8 justify-content-center">
            <p className="fs-sm pb-lg-3 ">Digitize your operations, protect your assets, and dramatically improve the quality and efficiency of your nondestructive evaluations.
            </p>
          </div>
          <div className="col-lg-3 col-md-2 col-sm-2"></div>
          </div>
          <div className="d-flex justify-content-center mt-1">
            <a href="https://www.linkedin.com/company/orbix-nde/" target="__blank" className="btn btn-icon btn-secondary btn-linkedin mx-2">
              <i className="bx bxl-linkedin"></i>
            </a>
            <a href="#register" className="btn btn-icon btn-secondary btn-youtube mx-2">
              <i className="bx bxl-youtube"></i>
            </a>
          </div>
          <p className="fs-sm text-center mt-4  mb-0">
            <span className="text-light opacity-60">&copy; Orbix 2024. All Rights Reserved.</span>
          </p>
          
          <Link to="/terms-of-services" target="__blank" className="fs-sm text-center mt-lg-5 pt-4 mb-0 text-decoration-none">
          <span className="text-primary">Terms of Conditions</span>
          </Link>
          <br/>
          <Link to="/privacy-policy" target="__blank" className="fs-sm text-center mt-lg-4 pt-3 mb-0 text-decoration-none">
          <span className="text-primary">Privacy Policy</span>
          </Link>
           
        </div>
      </footer>
        {/* Modal */}
        <Modal show={showModal} onClose={handleCloseModal}>
        <iframe 
          src="https://pitch.com/embed-link/n3v8zy" 
          allow="fullscreen" 
          allowFullScreen 
          width="560" 
          height="440" 
          margin="0"
          style={{ border: 0 , borderRadius:8 }}
        
        />
         <a className="no-thanks-text " onClick={handleCloseModal}>NO THANKS</a>
        </Modal>
        </>
    )
}

export default FooterSection;